import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import VuetifyToast from "./components/Global/Toast";
import axios from "axios";
import "./utils/filters";

Vue.config.productionTip = false;

const moment = require("moment");
require("moment/locale/es");

Vue.use(require("vue-moment"), {
  moment,
});

Vue.use(PerfectScrollbar);

Vue.use(VuetifyToast, {
  y: "top",
  showClose: true,
  queueable: true,
});

Vue.prototype.$http = axios;

new Vue({
  router,
  store,
  vuetify,
  created() {
    this.$store.dispatch("working", false);
    let token = this.$store.getters.token;
    if (token) {
      this.$http.defaults.headers["Authorization"] = "Bearer " + token;
    }
    this.$http.defaults.baseURL = process.env.VUE_APP_API_URL;
  },
  render: (h) => h(App),
}).$mount("#app");
