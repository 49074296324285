import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    token: null,
    working: false,
    user: {
      id: null,
      username: null,
      name: null,
      email: null,
      created_at: null,
      updated_at: null,
      deleted_at: null,
    },
  };
};

export default new Vuex.Store({
  state: getDefaultState,
  getters: {
    token: (state) => {
      return state.token;
    },
    user: (state) => {
      return state.user;
    },
    working: (state) => {
      return state.working;
    },
  },
  mutations: {
    reset: (state) => {
      const s = getDefaultState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    token: (state, payload) => {
      state.token = payload;
    },
    user: (state, payload) => {
      state.user = payload;
    },
    working: (state, payload) => {
      state.working = payload;
    },
  },
  actions: {
    reset: ({ commit }) => {
      commit("reset");
    },
    working: ({ commit }, payload) => {
      commit("working", payload);
    },
    user: ({ commit }, payload) => {
      commit("user", payload);
    },
    token: ({ commit }, payload) => {
      commit("token", payload);
    },
  },
  plugins: [createPersistedState()],
});
