<template>
  <v-overlay
    :value="active"
    :color="color"
    :opacity="opacity"
    style="z-index: 9999"
  >
    <BounceLoader :size="100" :color="spinnerColor" />
  </v-overlay>
</template>

<script>
import { BounceLoader } from "@saeris/vue-spinners";

export default {
  name: "VLoading",
  components: {
    BounceLoader,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#15112B",
    },
    opacity: {
      type: Number,
      default: 0.46,
    },
    spinnerColor: {
      type: String,
      default: "#e52b50",
    },
  },
};
</script>

<style scoped></style>
