<template>
  <v-app>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <v-loading :active="working" :opacity="0.75" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import VLoading from "./components/Global/Loading/loading";

export default {
  name: "App",
  components: {
    VLoading,
  },
  computed: {
    ...mapGetters(["working"]),
  },
};
</script>
