import Vue from "vue";

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
});
Vue.filter("numberFormat", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  let n = 0;
  let re = "\\d(?=(\\d{" + 3 + "})+" + (n > 0 ? "\\." : "$") + ")";
  return value.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
});
