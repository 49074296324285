import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        accent: "#ece8dd",
        primary: "#5F9EA0",
      },
    },
  },
  lang: {
    locales: { es },
    current: "es",
  },
});
