import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/lib/services/goto";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/app",
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/views/Layouts/Layout_Auth"),
    meta: {
      noAuth: true,
    },
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/views/Auth/Login"),
      },
    ],
  },
  {
    path: "/app",
    name: "app",
    meta: {
      needAuth: true,
    },
    component: () => import("@/views/Pages/Dashboard"),
  },
  {
    path: "/product-performance",
    name: "product-performance",
    component: () => import("@/views/Pages/product_performance/Layout_Main"),
    meta: {
      needAuth: true,
      breadCrumb: "Product Performance",
    },
    children: [
      {
        path: "clients",
        name: "clients",
        component: { render: (h) => h("router-view") },
        children: [
          
          {
            path: "list",
            name: "listClients",
            component: () =>
              import("@/views/Pages/product_performance/clients/client"),
          },
          {
            path: ":clientId",
            name: "detailClient",
            component: () =>
              import("@/views/Pages/product_performance/clients/detail"),
            meta: {
              breadCrumb: "Detail",
            },
          },
        ],
      },
      {
        path: "performance",
        name: "performance",
        component: () =>
          import("@/views/Pages/product_performance/home"),
      },
      {
        path: "products",
        name: "productosc",
        component: { render: (h) => h("router-view") },
        children: [
          {
            path: "list",
            name: "productoos",
            component: () =>
              import("@/views/Pages/product_performance/products/product"),
          },
        ],
      },
      {
        path: "usersPerformance",
        name: "usersPerformance",
        component: () => import("@/views/Pages/users/UsersP"),
      },
    ],
  },
  {
    path: "/business-daily",
    name: "business-daily",
    component: () => import("@/views/Pages/business_daily/Layout_Main"),
    meta: {
      needAuth: true,
      breadCrumb: "Business Daily",
    },
    children: [
      {
        path: "users",
        name: "users",
        component: () => import("@/views/Pages/users/Users"),
      },
      {
        path: "business",
        name: "business",
        component: () =>
        import(
          "@/views/Pages/business_daily/home"
        ),
      },
      {
        path: "ventas",
        name: "ventas",
        component: { render: (h) => h("router-view") },
        children: [
          {
            path: "facturacion",
            name: "facturacion",
            component: { render: (h) => h("router-view") },
            children: [
              {
                path: "diaria",
                name: "factDiaria",
                component: () =>
                  import(
                    "@/views/Pages/business_daily/facturacion/diaria/diaria"
                  ),
              },
              {
                path: "mensual",
                name: "factMensual",
                component: () =>
                  import(
                    "@/views/Pages/business_daily/facturacion/mensual/mensual"
                  ),
              },
              {
                path: "anual",
                name: "factAnual",
                component: () =>
                  import(
                    "@/views/Pages/business_daily/facturacion/anual/anual"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "produccion",
        name: "produccion",
        component: () =>
          import("@/views/Pages/business_daily/produccion/diaria/diaria"),
      },
      {
        path: "productos",
        name: "productos",
        component: () =>
          import("@/views/Pages/business_daily/productos/Productos"),
      },
      {
        path: "pedidos",
        name: "pedidos",
        component: () => import("@/views/Pages/business_daily/pedidos/Pedidos"),
      },
      {
        path: "eficiencia",
        name: "eficiencia",
        component: { render: (h) => h("router-view") },
        children: [
          {
            path: "efectividad-de-produccion",
            name: "efectividadProduccion",
            component: () =>
              import(
                "@/views/Pages/business_daily/efectividad/EfectividadProduccion"
              ),
          },
          {
            path: "quejas",
            name: "quejasCliente",
            component: () =>
              import("@/views/Pages/business_daily/quejas/Quejas"),
          },
        ],
      },
      {
        path: "recursos-humanos",
        name: "recursosHumanos",
        component: { render: (h) => h("router-view") },
        meta: {
          breadCrumb: "Recursos Humanos",
        },
        children: [
          {
            path: "personal",
            name: "personal",
            component: () =>
              import("@/views/Pages/business_daily/trabajadores/trabajadores"),
          },
          {
            path: "faltas",
            name: "faltas",
            component: () =>
              import("@/views/Pages/business_daily/trabajadores/faltas"),
          },
          {
            path: "accidentes",
            name: "accidentes",
            component: () =>
              import("@/views/Pages/business_daily/accidentes/Accidentes"),
          },
        ],
      },
    ],
  },
  {
    path: "/files",
    name: "files",
    component: () => import("@/views/Pages/files/new"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/test"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.needAuth)) {
    if (store.getters.token === null) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.noAuth)) {
    if (store.getters.token === null) {
      next();
    } else {
      let first = store.getters.user.permissions[0];
      if (first.name === "facturacion") {
        next({
          name: "factDiaria",
        });
      } else {
        next({
          name: first.name,
        });
      }
    }
  }
  next();
});

export default router;
